import { useState, createContext, useEffect } from "react"
import { dictionaryGlobal } from "apis/dictionary/global"
import { userLogin } from "apis/auth/login"
import { userProfile } from "apis/profile/userProfile"

const UserContext = createContext()

const UserProvider = (props) => {
  const [user, setUser] = useState({})
  const [userToken, setUserToken] = useState(localStorage["NTAK-userToken"])
  const [isAdmin, setIsAdmin] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [settings, setSettings] = useState(null)
  //const [newVersionAvailable, setNewVersionAvailable] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage["NTAK-selectedLanguage"] || "hu"
  )
  const [translations, setTranslations] = useState(
    JSON.parse(localStorage["NTAK-translations"] || "{}")
  )
  const [loading, setLoading] = useState(true)
  const [loginError, setLoginError] = useState(null)
  const [loginMessage, setLoginMessage] = useState(null)
  const [emailSubmitted, setEmailSubmitted] = useState(null)

  useEffect(() => {
    setLoading(true)
    if (localStorage["NTAK-userToken"] != null) {
      refreshToken(localStorage["NTAK-userToken"])
    } else {
      setStyles()
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dictionaryGlobal.getTranslations(selectedLanguage).then((res) => {
      if (res.success) {
        let obj = {}
        for (let item of res.data) {
          obj[item.key] = item[selectedLanguage]
        }
        localStorage["NTAK-selectedLanguage"] = selectedLanguage
        localStorage["NTAK-translations"] = JSON.stringify(obj)
        setTranslations(obj)
      } else {
        console.log(res)
      }
    })
  }, [selectedLanguage])

  // if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
  //   navigator.serviceWorker
  //     .register("/service-worker.js")
  //     .then((registration) => {
  //       registration.addEventListener("updatefound", () => {
  //         const installingWorker = registration.installing
  //         installingWorker.addEventListener("statechange", () => {
  //           if (installingWorker.state === "installed") {
  //             if (navigator.serviceWorker.controller) {
  //               const waitingWorker = registration.waiting
  //               if (waitingWorker) {
  //                 console.log("Service worker waiting, skip it")
  //                 // There's a waiting worker, post a message to skip waiting and activate the new worker
  //                 waitingWorker.postMessage({ type: "SKIP_WAITING" })
  //               }
  //               // A new version is available
  //               setNewVersionAvailable(true)
  //             } else {
  //               // The app is newly installed
  //               console.log("App installed")
  //             }
  //           }
  //         })
  //       })
  //     })
  // }

  const refreshToken = (token) => {
    userLogin.refreshUserToken(token).then((res) => {
      if (res.success) {
        localStorage["NTAK-userToken"] = res.data.token
        setUserToken(res.data.token)
        getUserData(res.data.token)
      } else {
        logout()
        setStyles()
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
    })
  }

  const getUserData = (token) => {
    userProfile.getProfile(token).then((res) => {
      if (res.success) {
        setUser(res.data)
        setIsAdmin(res.data.roles.includes("admin"))
        setLoggedIn(true)
        initUserSettings()
      } else {
        logout()
        setStyles()
        setTimeout(() => {
          setLoading(false)
        }, 500)
      }
    })
  }

  const initUserSettings = () => {
    if (localStorage["NTAK-userSettings"] != null) {
      try {
        let data = JSON.parse(localStorage["NTAK-userSettings"] || {})
        setSettings(data)
        setStyles(data.lightMode)
      } catch (e) {
        console.log(e)
        setStyles()
      }
      setTimeout(() => {
        setLoading(false)
      }, 300)
    } else {
      setStyles()
      setTimeout(() => {
        setLoading(false)
      }, 300)
    }
  }

  const saveSettings = (key, value) => {
    let newValues = { ...settings, [key]: value }
    localStorage["NTAK-userSettings"] = JSON.stringify(newValues)
    setSettings(newValues)
  }

  const addStyle = (name, value) => {
    document.documentElement.style.setProperty(name, value)
  }

  const setStyles = (lightMode) => {
    addStyle("--ten-color", "#2E9BD9")
    addStyle("--ten-color-80", "#58AFE1")
    addStyle("--ten-color-60", "#82C3E8")
    if (!lightMode) {
      addStyle("--sixty-color", "#1F2023")
      addStyle("--thirty-color", "#27282C")
      addStyle("--text-color", "#ECEFF1")
      addStyle("--border-color", "#383940")
    } else {
      addStyle("--sixty-color", "#F5F5F5")
      addStyle("--thirty-color", "#FAFAFA")
      addStyle("--text-color", "#000000")
      addStyle("--border-color", "#D9D9D9")
    }
  }

  const logout = () => {
    localStorage.removeItem("NTAK-userToken")
    localStorage.removeItem("NTAK-userEmail")
    localStorage.removeItem("NTAK-userSettings")
    setUserToken(null)
    setLoggedIn(false)
    resetLogin()
  }

  const generateToken = (email, pin) => {
    userLogin.generateUserToken(email, pin).then((res) => {
      if (res.success) {
        setUserToken(res.data.token)
        localStorage["NTAK-userToken"] = res.data.token
        getUserData(res.data.token)
      } else {
        console.log(res)
        setLoginError("ntak.login.pinError")
      }
    })
  }

  const generatePin = (email) => {
    userLogin.generateUserPin(email).then((res) => {
      if (res.success) {
        setLoginMessage("ntak.login.pinSent")
        setEmailSubmitted(true)
        setLoginError(null)
      } else {
        console.log(res)
        setLoginError("ntak.login.pinNotSent")
        setEmailSubmitted(false)
      }
    })
  }

  const resetLogin = () => {
    setLoginError(null)
    setLoginMessage(null)
    setEmailSubmitted(null)
  }

  return (
    <UserContext.Provider
      value={{
        user,
        settings,
        saveSettings,
        setStyles,
        setSelectedLanguage,
        selectedLanguage,
        translations,
        loading,
        loginError,
        loginMessage,
        emailSubmitted,
        generatePin,
        generateToken,
        resetLogin,
        userToken,
        loggedIn,
        logout,
        newVersionAvailable: false,
        isAdmin,
        refreshToken,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
